"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/web.timers.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _emptyFunction = _interopRequireDefault(require("fbjs/lib/emptyFunction"));

// import
// definition
var _default = function _default(facebookWallRef) {
  var _useState = (0, _react.useState)(500),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      width = _useState2[0],
      setWidth = _useState2[1];

  var resizeTimeoutRef = (0, _react.useRef)(setTimeout(_emptyFunction["default"], 0));
  var isMountedRef = (0, _react.useRef)(false);
  (0, _react.useEffect)(function () {
    var resize = function resize() {
      clearTimeout(resizeTimeoutRef.current);
      resizeTimeoutRef.current = setTimeout(function () {
        if (!facebookWallRef.current) return;
        var offsetWidth = facebookWallRef.current.offsetWidth;
        if (width === offsetWidth) return;
        setWidth(offsetWidth);
      }, 100);
    };

    if (!isMountedRef.current) resize();
    window.addEventListener('resize', resize);
    isMountedRef.current = true;
    return function () {
      clearTimeout(resizeTimeoutRef.current);
      window.removeEventListener('resize', resize);
    };
  }, [width, facebookWallRef]);
  return width;
};

exports["default"] = _default;